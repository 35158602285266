






































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { FileEntityModel, FileQueryModel } from '@common-src/entity-model/file-entity';
import FileService from '@common-src/service/file';

@Component
export default class LabelListComponent extends Mixins(TableDialogFormComponent) {
    FileEntityModel = FileEntityModel;
    created() {
        this.initTable({
            service: FileService,
            queryModel: new FileQueryModel(),
            tableColumns: FileEntityModel.getTableColumns()
        });
        this.getList();
    }
}

