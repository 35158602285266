import { ICRUDQ } from '@common-src/model/interface';
import { post } from './request';
import { FileEntityModel, FileQueryModel } from '@common-src/entity-model/file-entity';

const URL_PATH = `${COMMON_BASE_REQUEST_PATH}/filestorage`;
class FileService implements ICRUDQ<FileEntityModel, FileQueryModel> {
    create(model?: FileEntityModel): Promise<FileEntityModel> {
        throw new Error('Method not implemented.');
    }
    retrieve?(id: string): Promise<FileEntityModel> {
        throw new Error('Method not implemented.');
    }
    update(model?: FileEntityModel): Promise<FileEntityModel> {
        throw new Error('Method not implemented.');
    }
    delete(model?: FileEntityModel): Promise<FileEntityModel> {
        throw new Error('Method not implemented.');
    }
    async query(query?: FileQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return res;
    }
    sort?(sourceId: string, targetId: string) {
        throw new Error('Method not implemented.');
    }
}

export default new FileService();
