import { QueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { BaseEntityModel } from '@common-src/model/base-model';
export class FileEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '文件名称',
                dataIndex: 'filename'
            },
            {
                title: '文件地址',
                dataIndex: 'url'
            },
            {
                title: '文件大小',
                dataIndex: 'size'
            },
            {
                title: '文件类型',
                dataIndex: 'ext'
            },
            {
                title: '上传路径',
                dataIndex: 'path'
            },
            {
                title: '平台',
                dataIndex: 'platform'
            },
            {
                title: '上传人',
                dataIndex: 'userName'
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class FileQueryModel extends QueryModel {
    @QueryControl({
        label: '文件名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    fileName: string = undefined;

    toService() {
        return super.getParams();
    }
}
