import { Component, Ref } from 'vue-property-decorator';
import FormDialogComponent from './form-dialog-component';
import TableComponent from './table-component';
import { ViewModeType } from '@common-src/model/enum';

@Component
export default class TableDialogFormComponent<ModelT, SearchModelT> extends TableComponent<ModelT, SearchModelT> {
    // private tableModel: ModelT;
    /**
     * 弹窗表单组件
     */
    @Ref()
    public formDialog: FormDialogComponent<ModelT>;

    /**
     * 新增模型，打开弹窗事件
     * @param model 数据模型
     */
    addClick(model: ModelT): void {
        this.formDialog.dialogOpen(model, this.service, ViewModeType.NEW);
    }

    /**
     * 编辑模型，打开弹窗事件
     * @param model 数据模型
     * @param needGetDetail 是否需要调用详情接口
     */
    editClick(model, needGetDetail: boolean = false): void {
        // this.tableModel = model;
        this.formDialog.dialogOpen(model, this.service, ViewModeType.UPDATE, needGetDetail);
    }

    /**
     * 查看模型，打开弹窗事件
     * @param model 数据模型
     */
    viewClick(model: ModelT): void {
        this.formDialog.dialogOpen(model, this.service, ViewModeType.VIEW);
    }

    /**
     * 弹窗保存成功后的回掉事件
     * 重新查询表格数据
     */
    formDialogOK(params?: { viewMode: ViewModeType, res: any }): void {
        // if (params && params.res) {
        //     switch (params.viewMode) {
        //         case ViewModeType.UPDATE:
        //             this.updateTableList(params.res.id, this.listData, params.res);
        //             break;
        //         case ViewModeType.NEW:
        //             // this.listData.unshift(params.res);
        //             this.getList();
        //             break;
        //     }
        // } else {
        //     this.getList();
        // }
        this.getList();
    }

    updateTableList(currentId: string, children: Array<any>, updateModel: any) {
        if (currentId && children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                if (currentId === children[i].id) {
                    children[i] = updateModel;
                    this.$forceUpdate();
                    return;
                } else {
                    this.updateTableList(currentId, children[i].children, updateModel);
                }
            }
        }
    }
}
